@use 'sass:map';
@use 'breakpoints';
@use 'variables';

@use 'sass:math';
// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-row($gutter: variables.$grid-gutter-width) {
  --gutter-x: #{$gutter};
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-0.5 * var(--gutter-x));
  margin-left: calc(-0.5 * var(--gutter-x));
}

@mixin make-col-ready() {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-top: var(--gutter-y);
}

@mixin make-col($size: false, $columns: variables.$grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: math.percentage(math.div($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: variables.$grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, math.percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// number of columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: math.percentage(math.div(1, $count));
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns(
  $columns: variables.$grid-columns,
  $gutter: variables.$grid-gutter-width,
  $breakpoints: variables.$grid-breakpoints
) {
  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoints.breakpoint-infix($breakpoint, $breakpoints);

    @include breakpoints.media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      .row-cols#{$infix}-auto > * {
        @include make-col-auto();
      }

      @if variables.$grid-row-columns > 0 {
        @for $i from 1 through variables.$grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}
