input {
  &.toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    outline: 0;
    width: 40px;
    height: 24px;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    margin: 0;
    background: var(--color-grey-400);
    transition: all 0.2s ease;

    &.small {
      width: 32px;
      height: 16px;

      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--white-100);
        box-shadow: 0 1px 2px var(--color-shadow);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }

      &:checked {
        background: var(--color-green-500);
        &:after {
          transform: translatex(14px);
        }
        &:hover {
          background: var(--color-green-700);
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--white-100);
      box-shadow: 0 1px 2px var(--color-shadow);
      transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    }

    &:hover {
      background: var(--color-hover);
    }

    &:checked {
      background: var(--color-green-500);
      &:after {
        transform: translatex(16px);
      }
      &:hover {
        background: var(--color-green-700);
      }
    }
  }
}
