@use '@angular/material' as mat;

$caas-primary-palette: (
  50: #f1f5f9,
  100: #e3ecf3,
  200: #d6e2ee,
  300: #bacfe2,
  400: #97b6d3,
  500: #013476,
  600: #5286b6,
  700: #426b92,
  800: #31506d,
  900: #101b24,
  A100: #daeffb,
  A200: #c8e8fa,
  A400: #b5e0f8,
  A700: #547d94,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$caas-theme-accent: (
  50: #f1f5f9,
  100: #e3ecf3,
  200: #d6e2ee,
  300: #bacfe2,
  400: #97b6d3,
  500: #013476,
  600: #5286b6,
  700: #426b92,
  800: #31506d,
  900: #101b24,
  A100: #daeffb,
  A200: #c8e8fa,
  A400: #b5e0f8,
  A700: #547d94,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$caas-theme-warn: (
  50: #fcf2f4,
  100: #f9e6e8,
  200: #ffbdc5,
  300: #ff5468,
  400: #fb001d,
  500: #d4051d,
  600: #b50015,
  700: #9e0012,
  800: #61000b,
  900: #3c0007,
  A100: #f4cccc,
  A200: #e06666,
  A400: #e06666,
  A700: #cc0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$custom-primary: mat.m2-define-palette($caas-primary-palette);
$custom-accent: mat.m2-define-palette($caas-theme-accent);
$custom-warn: mat.m2-define-palette($caas-theme-warn);
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Thieme',);
$custom-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $custom-primary,
        accent: $custom-accent,
        warn: $custom-warn,
      ),
      typography: $custom-typography,
    )
);


@include mat.all-component-themes($custom-theme);
body {
  font: mat.get-theme-typography($custom-theme, body-1);
  letter-spacing: mat.get-theme-typography($custom-theme, body-1, letter-spacing);
}

:root {
  --white-100: #ffffff;
  --blue-moon: #c7d1e1;
  --neutral-100: #eff5fa;
  --brand-blue-light: #a2d7f6;
  --brand-blue-dark: #013374;
  --text-heading-primary: #013476;
  --text-heading-secondary: #242f46;
  --text-heading-tertiary: #56647e;
  --link-default: #2463eb;
  --color-grey-800: #11182e;
  --text-content-primary: #242f46;
  --text-content-secondary: #c6d0e0;
  --text-content-tertiary: #b4bdcc;
  --text-label: #242f46;
  --outline-focus: #f87315;
  --color-shadow: #2c2c2c33;
  --color-hover: #687999;

  --color-blue-50: #f1f5f9;
  --color-blue-100: #e3ecf3;
  --color-blue-200: #d6e2ee;
  --color-blue-250: #c8d9e8;
  --color-blue-300: #bacfe2;
  --color-blue-400: #97b6d3;
  --color-blue-500: #759ec5;
  --color-blue-600: #5286b6;
  --color-blue-700: #426b92;
  --color-blue-800: #31506d;
  --color-blue-850: #213649;
  --color-blue-900: #101b24;

  --color-dark-blue-50: #f7fafc;
  --color-dark-blue-100: #e4ecf5;
  --color-dark-blue-200: #d6e3f3;
  --color-dark-blue-300: #a6ceff;
  --color-dark-blue-400: #99aec8;
  --color-dark-blue-500: #5678a3;
  --color-dark-blue-600: #305a8f;
  --color-dark-blue-700: #013476;
  --color-dark-blue-800: #011d42;

  --color-light-blue-50: #edf7fd;
  --color-light-blue-100: #daeffb;
  --color-light-blue-200: #c8e8fa;
  --color-light-blue-300: #b5e0f8;
  --color-light-blue-400: #a3d8f6;
  --color-light-blue-450: #81c7f1;
  --color-light-blue-500: #82adc5;
  --color-light-blue-600: #547d94;

  --color-green-50: #ebfef5;
  --color-green-100: #dff7e9;
  --color-green-400: #009e4f;
  --color-green-500: #008844;
  --color-green-600: #007d3e;
  --color-green-700: #005c2e;
  --color-green-800: #08412b;

  --color-yellow-50: #fef9e4;
  --color-yellow-100: #fdf2cc;
  --color-yellow-200: #fbe599;
  --color-yellow-400: #f7cb34;
  --color-yellow-500: #f5be01;
  --color-yellow-600: #c49801;
  --color-yellow-700: #937201;
  --color-yellow-800: #624c00;
  --color-yellow-900: #312600;

  --color-red-50: #fcf2f4;
  --color-red-100: #f9e6e8;
  --color-red-600: #b50015;
  --color-red-700: #9e0012;
  --color-red-800: #61000b;

  --color-grey-50: #f6f8fc;
  --color-grey-100: #eef4fa;
  --color-grey-200: #e0e6ee;
  --color-grey-300: #c6d0e0;
  --color-grey-400: #8b99b1;
  --color-grey-450: #687999;
  --color-grey-500: #56647e;
  --color-grey-600: #39445b;
  --color-grey-700: #242f46;
  --color-grey-800: #11182e;
  --color-grey-800-alpha-80: rgba(17, 24, 46, 0.8);

  --input-field-background-default: #f1f5f9;
}

.is-theme-dark {
  background-color: var(--color-grey-800);
  --text-content-primary: #f6f8fc;
}

::highlight(search-highlight) {
  background-color: var(--color-yellow-400);
}
