// Custom styling for angular material
.mat-mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: var(--color-blue-200);
  --mdc-plain-tooltip-supporting-text-color: var(--text-content-primary);
  --mdc-plain-tooltip-supporting-text-weight: 600;
  --mdc-plain-tooltip-supporting-text-tracking: 0.12px;
  --mdc-plain-tooltip-supporting-text-line-height: 150%;
  padding: 10px;

  .is-theme-dark & {
    --mdc-plain-tooltip-container-color: var(--color-grey-800-alpha-80);
  }
}

.mat-mdc-tooltip-panel {
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    z-index: 1;
    border: 8px solid transparent;

    .is-theme-dark & {
      border-top: 8px solid var(--color-grey-800-alpha-80);
    }
  }

  &.mat-mdc-tooltip-panel-above:after {
    border-top: 8px solid var(--color-blue-200);
    translate: -50% 50%;
    left: 50%;
    bottom: -8px;
  }
  &.mat-mdc-tooltip-panel-below:after {
    border-bottom: 8px solid var(--color-blue-100);
    translate: -50% -50%;
    left: 50%;
    top: -8px;
  }
  &.mat-mdc-tooltip-panel-left:after {
    border-left: 8px solid var(--color-blue-100);
    translate: 50% -50%;
    right: -8px;
    top: 50%;
  }
  &.mat-mdc-tooltip-panel-right:after {
    border-right: 8px solid var(--color-blue-100);
    translate: -50% -50%;
    left: -8px;
    top: 50%;
  }
}
