@font-face {
  font-family: 'Thieme';
  src: url('../assets/fonts/ThiemeArgo2023_VF.woff2') format('woff2');
}

@font-face {
  font-family: 'Thieme-Italic';
  src: url('../assets/fonts/ThiemeArgo2023_VF_IT.woff2') format('woff2');
}

body {
  font-family: Thieme, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.display-l,
.display-m,
.display-s,
.heading-l,
.heading-m,
.heading-s {
  font-weight: 300;
  font-style: normal;
  color: var(--text-heading-primary);
}

.display-l {
  /* Display/Display L Light */
  color: var(--text-heading-primary);
  font-size: 3.5rem;
  line-height: 115%;
}

.display-m {
  /* Display/Display M Light */
  font-size: 3rem;
  line-height: 115%;
}

.display-s {
  /* Display/Display S Bold */
  font-size: 2.5rem;
  line-height: 115%;
}

.heading-l {
  /* Heading/Heading L Semibold */
  font-size: 2rem;
  line-height: 120%;
}

.heading-m {
  /* Heading/Heading M Semibold */
  font-size: 1.75rem;
  line-height: 120%;
}

.heading-s {
  /* Heading/Heading S Semibold */
  font-size: 1.5rem;
  line-height: 120%;
}

.heading-xs {
  color: var(--text-heading-primary);
  font-size: 1.25rem;
}

[class^='text-'] {
  font-style: normal;
  line-height: 150%;
}

.text-l,
.size-l {
  font-size: 1, 1875rem;
}

.text-m,
.size-m {
  font-size: 1rem;
}

.text-s,
.size-s {
  font-size: 0.875rem;
}

.text-xs,
.size-xs {
  font-size: 0.75rem;
}

.m-bold {
  font-weight: 700;
}

.m-semibold {
  font-weight: 600;
}

.m-medium {
  font-weight: 500;
}

.m-light {
  font-weight: 300;
}

a {
  color: var(--link-default);
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  text-decoration-line: underline;
}

.is-theme-dark {
  .display-l,
  .display-m,
  .display-s {
    color: var(--text-content-primary);
  }

  .heading-l,
  .heading-m,
  .heading-s,
  .heading-xs {
    color: var(--text-content-secondary);
  }

  .text-l,
  .text-m,
  .text-s,
  .text-xs {
    color: var(--text-content-tertiary);
  }
}

label {
  color: var(--text-label);
}
