@use 'breakpoints';
@use 'grid';

:root {
  --grid-columns: 12;
  --gutter-x: 24px;
  --gutter-y: 32px;
}

@include breakpoints.media-breakpoint-down(sm) {
  :root {
    --gutter-x: 8px;
    --gutter-y: 12px;
  }
}

@include breakpoints.media-breakpoint-between(sm, md) {
  :root {
    --gutter-x: 16px;
    --gutter-y: 24px;
  }
}

.row {
  @include grid.make-row();

  > * {
    @include grid.make-col-ready();
  }
}

// Columns
//
// Common styles for small and large grid columns

@include grid.make-grid-columns();
